@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */
@import "_setting.scss";



/************************************

MAINIMAGE

************************************/
#error #mainimage {
	background:url(../contact/images/mainimage.jpg) center top;
	background-size:cover;
	height:300px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
	#error #mainimage {
	background:url(../contact/images/mainimage@2x.jpg);
	background-size:cover;
}
}
@media only screen and (max-width:767px){
	#error #mainimage {
	background:url(../contact/images/mainimage-sp@2x.jpg)center top;
	background-size:cover;
	height:120px;
}
}

//************************************

// #error_main

//************************************/
#error_main{
margin:60px 0 0;
p{
line-height: 1.8;
margin:0 0 30px;
@include media-tab { 
text-align: left;
}
}
.bt{
a{
max-width: 400px;
}
}
}