@charset "UTF-8";

//setting
$main-color: #333;
$key-color1: #e69942;//orange
$key-color2: #8a6e90;//budo
$key-color3: #f14a64;//momo
$key-color4: #f1603f;//ringo
$key-color5: #bb9f00;//nashi
$key-color6: #754c24;//kogecha
$back-color1: #F3F5F7;//gray
$back-color2: #f5f1da;//pale beige
$red:#D93C3C;


$gothic: 'Noto Sans JP',"Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$mincho: 'Shippori Mincho', serif;
$eng: 'Dancing Script', cursive;
$eng2: 'Ribeye Marrow', cursive;


//max-width
$max-width-l:950px;

// media query
$tab: 1024px;//960px; // タブレット767px
$sp: 600px; // スマホ480px

@mixin media-tab {
  @media only screen and (max-width: ($tab)) {
    @content;
  }
}
// 呼び出すときは　@include media-tab { }
@mixin media-sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}
// 呼び出すときは　@include media-sp { }

//clearfix 呼び出すときは　@include clearfix;
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
